import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { injectIntl, Link } from 'gatsby-plugin-react-intl'

class IndexPage extends React.Component {
  render() {
    function createImage(img) {
      return (
        <figure className="image post-thumbnail">
          <img src={img.sourceUrl} alt={img.altText} className="rounded-top" />
        </figure>
      )
    }

    const { posts, title, intl } = this.props

    return (
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="has-text-weight-bold is-size-2 title gradient-box">
              {title}
            </h1>
          </div>
          <div
            className="columns is-multiline is-flex"
            style={{ alignItems: 'stretch' }}
          >
            {posts.map(({ node: post }) => (
              <div
                className="column is-6 is-flex"
                // style={{ padding: '2em 4em' }}
                key={post.id}
              >
                <div className="shadow-box">
                  {post?.featuredImage?.node &&
                    createImage(post?.featuredImage?.node)}
                  <div className="content" style={{ padding: '2em 4em' }}>
                    <div>
                      <Link
                        className="has-text-primary is-4 rainbow-link title-link"
                        to={`/${post.slug}`}
                      >
                        {post.title}
                      </Link>
                      {/* <span> &bull; </span> */}
                      <div>
                        <small>
                          {`${post.date} - ${intl.messages.postedBy} `}
                          <Link to={`/author/${post.author.node.slug}`}>
                            {post.author.node.name}
                          </Link>
                        </small>
                      </div>
                    </div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.excerpt.replace(
                            /<p class="link-more.*/,
                            ''
                          ),
                        }}
                      />
                      <div className="has-text-right">
                        <Link
                          className="has-text-primary rainbow-link title-link is-size-5"
                          to={`/${post.slug}`}
                        >
                          {`${intl.messages.readMore} →`}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default injectIntl(IndexPage)

export const pageQuery = graphql`
  fragment PostListFields on WpPost {
    id
    title
    excerpt
    author {
      node {
        name
        slug
        avatar {
          url
        }
      }
    }
    featuredImage {
      node {
        sourceUrl
        sizes
        title
        altText
      }
    }
    date(formatString: "DD-MM-YYYY")
    slug
  }
`

//TODO slug...
// featured_media {
//   localFile {
//     childImageSharp {
//       fixed {
//         src
//         originalName
//       }
//     }
//   }
// }
